body{
    background-color: #a4de74;
}

.karved-container{
    min-height: 100vh;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 50px;
    padding-top: 50px;
}

.karved-navbar{
    width: 80%;
    margin: 0px auto;
    height: 75px;
    border: 1px solid #f7f7f7;
    box-shadow: 5px 10px 5px #f7f7f7;
    position: relative;

    .logo {
        height:70px;
    }

    .signup{
        position: absolute;
        right: 10px;
        top: 5px;
        padding-top: 20px;
        width:175px;
        border-radius: 10px;
        background-color: #FFF;
        color: #5e17eb;
        border: 3px solid #5e17eb;
        cursor: pointer;

        &:hover{
            background-color: #5e17eb;
            color: #FFF;
        }
    }

   //Media Query at below 768px
    @media (max-width: 768px){
        width: 100%;
        height: 100px;
        padding-top: 15px;
        .signup{
            right: 0;
            top: 5px;
            width: 100px;
            height:90px;
            padding-top: 10px;
        }
    }

}

.karved-section{
    min-height: 100vh;
}

#signup{
   
    padding: 50px 0;
}

.sign-up-form{
    margin-top: 50px;
    margin-bottom: 100px;
    border: 2px solid #f7f7f7;
    box-shadow: 5px 10px 5px #f7f7f7;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    
}

.sign-up-button {
    //Orange
    background-color: #5e17eb;
    color: #fff;
    border: none;
    padding-top: 25px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    margin-top: 20px;
    box-shadow: 5px 10px 5px #9b9999;
}

.footer{
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;

    a {
        color: #5e17eb;
        margin-left: 15px;
        &:first-of-type{
            margin-left: 0;
        }
    }
}

.cookie-agreement{
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 140px;
    background-color: #fff;
    border-top: 1px solid #f7f7f7;
    text-align: center;
    padding-top: 20px;
    
    button {
        background-color: #5e17eb;
        color: #fff;
        border: none;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 20px;
    }
}